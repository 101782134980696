import React, {useEffect, useState} from "react";
import {DrugBatch, DrugPack} from "../../../../../api/mm";
import {toArray} from "../../../../../utils/sortingUtils";
import {useDispatch, useSelector} from "react-redux";
import {nullifyDrugPacksWithContentsListStore} from "../../../../../store/drugPackWithContentsList/actions/DrugPackWithContentsListActions";
import {fetchAllDrugPacks} from "../../../../../store/drugPackList/actions/DrugPackListActions";
import {RootStore} from "../../../../../store/Store";
import {
    DrugBatchExpires,
    DrugExpiryItem,
    DrugExpiryType,
    getDrugBatchExpires,
    getExpiryItemsForTableStats
} from "../Helpers/medicineManagementStatsHelpers";
import moment from "moment";
import DrugExpiryTable from "./DrugExpiryTable";

const StatisticsForMedicineManagement = (props: DrugBatch[]) => {
    const [expires, setExpires] = useState<DrugBatchExpires>({
        activeBatches: [],
        expiredDrugs: [],
        expiringInTheWeek: [],
        expiringInTheMonth: []
    });
    const [expiredDrugs, setExpiredDrugs] = useState<DrugExpiryItem[]>([]);
    const [drugsExpiringInWeek, setDrugsExpiringInWeek] = useState<DrugExpiryItem[]>([]);
    const [drugsExpiringInMonth, setDrugsExpiringInMonth] = useState<DrugExpiryItem[]>([]);
    const dispatch = useDispatch();

    const drugPackListStore = useSelector((state: RootStore) => state.drugPackList);

    // eslint-disable-next-line @typescript-eslint/ban-ts-comment
    // @ts-ignore
    useEffect(() => {
        //Get all drug packs
        dispatch(fetchAllDrugPacks());
        return async function () {
            dispatch(nullifyDrugPacksWithContentsListStore());
        };
    }, [dispatch]);

    useEffect(() => {
        setExpires(getDrugBatchExpires(toArray(props), moment()));
    }, [props]);

    useEffect(() => {
        if (drugPackListStore.loading) return;
        if (drugPackListStore.error) return;
        if (!drugPackListStore.data) return;
        if (drugPackListStore.data.length === 0) return;
        if (expires.activeBatches.length === 0) return;

        const drugPackList: DrugPack[] = drugPackListStore.data;
        //Generate all items expiring in the month and week.
        const drugExpiryItems: DrugExpiryItem[] = getExpiryItemsForTableStats(
            expires.activeBatches,
            drugPackList
        );

        const expired = sortDrugExpiryItemByExpiryAscending(
            drugExpiryItems.filter(
                (el: DrugExpiryItem) => el.drugExpiryType === DrugExpiryType.Expired
            )
        );

        //Filter by batches expiring in week
        const expiringInWeek = sortDrugExpiryItemByExpiryAscending(
            drugExpiryItems.filter(
                (el: DrugExpiryItem) => el.drugExpiryType === DrugExpiryType.Week
            )
        );
        //Filter by batches expiring in month
        const expiringInMonth = sortDrugExpiryItemByExpiryAscending(
            drugExpiryItems.filter(
                (el: DrugExpiryItem) => el.drugExpiryType === DrugExpiryType.Month
            )
        );
        setExpiredDrugs(expired);
        setDrugsExpiringInWeek(expiringInWeek);
        setDrugsExpiringInMonth(expiringInMonth);
    }, [
        drugPackListStore.loading,
        drugPackListStore.error,
        drugPackListStore.data,
        expires.activeBatches
    ]);

    //Sorts items so items expiring first will show at the top
    const sortDrugExpiryItemByExpiryAscending = (drugExpires: DrugExpiryItem[]) => {
        return drugExpires.sort((a, b) => {
            if (a.expiry > b.expiry) {
                return 1;
            }
            if (a.expiry < b.expiry) {
                return -1;
            }
            return 0;
        });
    };

    return (
        <React.Fragment>
            <DrugExpiryTable
                noItemsMessage={"All expired drugs have been actioned"}
                expiries={expiredDrugs}
                expiryType={DrugExpiryType.Expired}
            />
            <DrugExpiryTable
                noItemsMessage={"There are no drugs that are expiring in the next 7 days"}
                expiries={drugsExpiringInWeek}
                expiryType={DrugExpiryType.Week}
            />
            <DrugExpiryTable
                noItemsMessage={"There are no drugs that are expiring in the next 30 days"}
                expiries={drugsExpiringInMonth}
                expiryType={DrugExpiryType.Month}
            />
        </React.Fragment>
    );
};

export default StatisticsForMedicineManagement;
