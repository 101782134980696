import React, {useEffect} from "react";
import {routeNames} from "../../Navigation/routeNames";
import {useDispatch, useSelector} from "react-redux";
import {NavigationItem} from "../../../store/navigation/actions/NavigationActionTypes";
import {setNavigationItems} from "../../../store/navigation/actions/NavigationActions";
import {nullifyDrugBatchPagedStore} from "../../../store/drugBatchPaged/actions/DrugBatchPagedActions";
import StockManagementTable from "./Components/StockManagementTable";

import {WithServiceState} from "store-fetch-wrappers";
import {RootStore} from "../../../store/Store";
import TopBar from "../Layouts/Layout/Components/TopBar/TopBar";
const ServiceWrapper = WithServiceState(StockManagementTable);

const StockManagement = () => {
    const dispatch = useDispatch();
    const drugBatchPagedStore = useSelector((state: RootStore) => state.drugBatchPaged);

    useEffect(() => {
        const nav: NavigationItem[] = [
            {
                name: routeNames.medicineManagement.name,
                path: routeNames.medicineManagement.path
            },
            {
                name: routeNames.stockManagement.name,
                path: routeNames.stockManagement.path
            },
            {
                name: routeNames.generalReports.name,
                path: routeNames.generalReports.path
            },
            {
                name: routeNames.mmAuditsList.name,
                path: routeNames.mmAuditsList.path
            },
            {
                name: routeNames.auditLocationList.name,
                path: routeNames.auditLocationList.path
            }
        ];

        dispatch(setNavigationItems(nav));

        return function () {
            dispatch(nullifyDrugBatchPagedStore());
        };
    }, []);

    return (
        <>
            <TopBar titleText="Stock Management" />
            <div className="page-container">
                <ServiceWrapper
                    showLoadingText={true}
                    loaderType={"overlay"}
                    loaderWheelType={"three-ring"}
                    placeholderBlockCount={1}
                    {...drugBatchPagedStore}
                />
            </div>
        </>
    );
};

export default StockManagement;
