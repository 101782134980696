import React from "react";
import {DrugBatch} from "../../../../../api/mm";
import {useSelector} from "react-redux";
import {RootStore} from "../../../../../store/Store";
import {HiCheckCircle, HiExclamationCircle} from "react-icons/hi";
import {TbCalendarClock} from "react-icons/tb";
import {RxCalendar} from "react-icons/rx";
import moment from "moment";

interface DrugValues {
    drugsInDate: number;
    drugsExpired: number;
    drugs7Days: number;
    drugs30Days: number;
}

function getDrugValues(drugs: DrugBatch[]): DrugValues {
    const now = moment().unix();
    const oneWeekInFuture = moment().add(1, "week").unix();
    const oneMonthInFuture = moment().add(1, "month").unix();
    let inDate = 0;
    let expired = 0;
    let week = 0;
    let month = 0;

    for (const drug of drugs) {
        if (drug.expiryDate <= now) ++expired;
        else if (drug.expiryDate <= oneWeekInFuture) ++week;
        else if (drug.expiryDate <= oneMonthInFuture) ++month;
        else ++inDate;
    }

    return {
        drugsInDate: inDate,
        drugsExpired: expired,
        drugs7Days: week,
        drugs30Days: month
    };
}

export default function DrugStats() {
    const drugBatchListRawStore = useSelector((state: RootStore) => state.drugBatchList);
    const drugValues = getDrugValues(drugBatchListRawStore.data || []);

    return (
        <>
            <div className="drug-widgets">
                <div className="drug-widget">
                    <div className="drug-widget-icon-wrapper">
                        <div className="drug-widget-icon">
                            <HiCheckCircle size={40} />
                        </div>
                        <p className="drug-widget-count">{drugValues.drugsInDate}</p>
                    </div>
                    <a className="drug-widget-description" href="https://mm.medicare-ems.co.uk">
                        In date
                    </a>
                </div>

                <div className="drug-widget drug-widget-expired">
                    <div className="drug-widget-icon-wrapper">
                        <div className="drug-widget-icon">
                            <HiExclamationCircle size={40} />
                        </div>
                        <p className="drug-widget-count">{drugValues.drugsExpired}</p>
                    </div>
                    <a className="drug-widget-description" href="https://mm.medicare-ems.co.uk">
                        Expired
                    </a>
                </div>

                <div className="drug-widget drug-widget-7">
                    <div className="drug-widget-icon-wrapper">
                        <div className="drug-widget-icon-rounded">
                            <TbCalendarClock size={28} />
                        </div>
                        <p className="drug-widget-count">{drugValues.drugs7Days}</p>
                    </div>
                    <a className="drug-widget-description" href="https://mm.medicare-ems.co.uk">
                        Expiring within 7 days
                    </a>
                </div>

                <div className="drug-widget drug-widget-30">
                    <div className="drug-widget-icon-wrapper">
                        <div className="drug-widget-icon-rounded">
                            <RxCalendar size={27} />
                        </div>
                        <p className="drug-widget-count">{drugValues.drugs30Days}</p>
                    </div>
                    <a className="drug-widget-description" href="https://mm.medicare-ems.co.uk">
                        Expiring within 1 month
                    </a>
                </div>
            </div>
        </>
    );
}
