import React from "react";

import {WithServiceState} from "store-fetch-wrappers";
import BackgroundContainer from "../../Containers/BackgroundContainer";
import StatisticsForMedicineManagement from "./Components/Stats/StatisticsForMedicineManagement";
import DrugPackTableContainer from "./Components/DrugPackView/DrugPackTableContainer";
import FormHeaderWithAction from "../../Form/FormHeaderWithAction";
import {IconType} from "../../Icon/Icon";
import {useMedicineManagement} from "./Hooks/useMedicineManagement";
import TableActionContainer from "../../Table/TableActionContainer";
import MCButton, {ButtonColourOptions, ButtonSize} from "../../Button/MCButton";
import TopBar from "../Layouts/Layout/Components/TopBar/TopBar";
import DrugStats from "./Components/Stats/DrugStats";

const StatsServiceWrapper = WithServiceState(StatisticsForMedicineManagement);

const MedicineManagement = () => {
    const {activeDrugBatchStore, viewNewDrugPackCategories, createNewDrugPack} =
        useMedicineManagement();
    return (
        <React.Fragment>
            <TopBar titleText="Medicine Management" />
            <DrugStats />
            <BackgroundContainer background={"background-stars"}>
                <div className="pt-4">
                    <TableActionContainer>
                        <MCButton
                            size={ButtonSize.Large}
                            innerValue={"Create Drug Pack"}
                            onClick={createNewDrugPack}
                            colour={ButtonColourOptions.Yellow}
                            roundedCorner
                        />
                    </TableActionContainer>
                    <FormHeaderWithAction
                        tooltipText={"View Drug Pack Categories"}
                        showIcon={true}
                        icon={IconType.Eye}
                        onClick={viewNewDrugPackCategories}
                        iconSize={"Large"}
                        headerName={"Drug Packs"}
                    />
                    <DrugPackTableContainer />
                </div>
            </BackgroundContainer>
            <BackgroundContainer background={"background-chevrons"}>
                <div className="pt-4">
                    <StatsServiceWrapper
                        showLoadingText={true}
                        loaderType={"overlay"}
                        loaderWheelType={"three-ring"}
                        placeholderBlockCount={1}
                        {...activeDrugBatchStore}
                    />
                </div>
            </BackgroundContainer>
        </React.Fragment>
    );
};

export default MedicineManagement;
